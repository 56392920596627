import React from 'react';
import { useOrders } from '../../hooks/app/orders/useOrders';
import { OrderEvent, OrderStatus } from '@oolio-group/domain';
import { useCallback } from 'react';
import useSWR from 'swr';
import { groupEventsByOrderId } from '../../utils/eventHelper';
import { useOrderEvents } from '../../hooks/app/orders/useOrderEvents';
import { useSession } from '../../hooks/app/useSession';
import { ordersReceivedViaPollingAt } from '../../state/cache';
import { REACT_APP_ORDERS_POLLING_URL } from 'react-native-dotenv';
import { tokenUtility } from '../../state/tokenUtility';
import { getExpiresAfter } from '../../state/preferences';
import { refreshTokenAccessToken } from '../../utils/refreshTokenAccessToken';
import { useLogout } from '../../hooks/app/useLogout';

export type headers = {
  'Content-Type': string;
  authorization?: string;
};

const POLLING_INTERVAL = 10000;
const ORDERS_POLLING_URI =
  process.env['REACT_APP_ORDERS_POLLING_URL'] ||
  REACT_APP_ORDERS_POLLING_URL ||
  'http://localhost:4023/open';

export const OrderHermes: React.FC = () => {
  const { returnFilteredOrdersFromCache } = useOrders();
  const [session] = useSession();
  const { logout } = useLogout();

  const { orderEventsHandler } = useOrderEvents();
  const isOnlineOrdersEnabled = session?.deviceProfile?.enableOnlineOrders;
  const orderQueries: { status: OrderStatus; isOnline?: boolean }[] = [
    { status: OrderStatus.IN_PROGRESS },
    { status: OrderStatus.ON_HOLD },
    { status: OrderStatus.ON_ACCOUNT },
  ];
  if (isOnlineOrdersEnabled) {
    orderQueries.push({ status: OrderStatus.IN_PROGRESS, isOnline: true });
    orderQueries.push({ status: OrderStatus.CREATED, isOnline: true });
  }
  const openOrders = returnFilteredOrdersFromCache(orderQueries);
  const ordersWithLastEvent = openOrders.map(
    order => `${order.id}#${order.prevEventId}`,
  );

  // TODO: skip polling API call if isConnected === false
  // TODO : to add authorization in headers
  const fetcher = useCallback(
    async (url: string) => {
      if ((tokenUtility.expiresAfter as number) < getExpiresAfter(0)) {
        await refreshTokenAccessToken().catch(logout);
      }
      return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          ids: ordersWithLastEvent,
          store: session?.currentStore?.id,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: tokenUtility.token as string,
          device: session?.device?.id as string,
          organization: session?.currentOrganization?.id as string,
          venue: session?.currentVenue?.id as string,
          store: session?.currentStore?.id as string,
        },
      }).then(r => r.json());
    },
    [
      ordersWithLastEvent,
      session?.currentStore?.id,
      session?.device?.id,
      session?.currentOrganization?.id,
      session?.currentVenue?.id,
      logout,
    ],
  );

  useSWR(ORDERS_POLLING_URI, fetcher, {
    refreshInterval: POLLING_INTERVAL,
    dedupingInterval: POLLING_INTERVAL,
    onSuccess(events: OrderEvent[]) {
      if (events?.length) {
        ordersReceivedViaPollingAt(performance.now());
        const eventsMap: Record<string, OrderEvent[]> =
          groupEventsByOrderId(events);
        for (const key in eventsMap) {
          const eventArray: OrderEvent[] = eventsMap[key];
          eventArray.sort((a, b) => a.timestamp - b.timestamp);
          orderEventsHandler(eventArray);
        }
      }
    },
  });
  return <></>;
};
